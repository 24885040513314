/**
 * @license
 *
 * Font Family: Poppins
 * Designed by: Jonny Pinhorn, Indian Type Foundry
 * URL: https://www.fontshare.com/fonts/poppins
 * © 2022 Indian Type Foundry
 *
*/

@font-face {
  font-family: 'Poppins-Variable';
  src: url('./assets/fonts/Poppins-Variable.woff2') format('woff2');
  font-weight: 300 900;
  font-display: swap;
  font-style: normal;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  font-family:
    'Poppins-Variable',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

hr {
  height: 0;
  border: 0;
  border-top: 1px solid #eaecf0;
}

.rdp-day_outside {
  color: #667085;
}

.rdp-day_today {
  background-color: #eaecf0;
}

input[type='checkbox']:disabled + label {
  color: var(--colors-gray300);
}

.phone-input {
  border: none !important;
  border-radius: 0.5rem !important;
  padding: 0.5rem 3.5rem !important;
  width: 100% !important;
}

.phone-input:disabled {
  background-color: #eaecf0 !important;
}

a {
  text-decoration: none;
}

.select-box-wrapper {
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
}
